import { Trans } from 'react-i18next';
import { PricingPackagesTotalPrice } from '../../../../../store/features/packages/types';
import { MuiBox } from '../../../../../../../modules/material';
import Typography, { TypographyProps } from '../../../../library/Typography';

import { StyledTotalRow } from './styles';

export interface SummaryTotalsProps {
  totalPrice: PricingPackagesTotalPrice;
  titleVariant?: TypographyProps['variant'];
}
export default function SummaryTotals(props: SummaryTotalsProps) {
  const {
    totalPrice: {
      subtotal = '',
      taxLabel = '',
      vatRate,
      totalVat,
      totalInclVat,
      displayPriceWithVat,
    },
    titleVariant,
  } = props;

  return (
    <MuiBox>
      {subtotal && (
        <StyledTotalRow>
          <Typography variant={titleVariant}>
            <Trans i18nKey="site.pricing.order-summary.total-subtotal.label" />
          </Typography>
          <Typography variant="featuredText">{subtotal}</Typography>
        </StyledTotalRow>
      )}
      <StyledTotalRow>
        <Typography variant={titleVariant}>
          <Trans
            i18nKey={`site.pricing.order-summary.total-${taxLabel.toLowerCase()}${
              displayPriceWithVat ? '.incl' : ''
            }.label`}
            values={{ 0: vatRate }}
          />
        </Typography>
        <Typography>{totalVat}</Typography>
      </StyledTotalRow>
      <StyledTotalRow>
        <Typography variant={titleVariant}>
          <Trans i18nKey="site.pricing.order-summary.total-total.label" />
        </Typography>
        <Typography variant="h4">{totalInclVat}</Typography>
      </StyledTotalRow>
    </MuiBox>
  );
}
