import { useRootSelector } from '../../../../../store/hooks';
import { selectSiteHeader } from '../../../../../store/features/siteHeader';
import { useGetImgixImageUrl } from '../../../../../hooks';

export function usePackageThumbnailData() {
  const siteHeader = useRootSelector(selectSiteHeader);
  const {
    data: {
      packageThumbnail: {
        backgroundImageUrl = '',
        whiteLogoUrl,
      },
    },
    meta: { title: siteTitle },
  } = siteHeader;

  return {
    backgroundImageUrl: useGetImgixImageUrl(backgroundImageUrl),
    whiteLogoUrl: useGetImgixImageUrl(whiteLogoUrl),
    siteTitle,
  };
}
