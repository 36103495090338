import { useRootSelector } from '../../../../../../../../store/hooks';
import {
  selectSitePricingOutdatedPackagesByDelegate,
  selectSitePricingPackagesByDelegate,
} from '../../../../../../store/features/packages';
import PackagesListByDelegate from '../PackagesListByDelegate';

export interface MiniBasketPackagesListProps {
  mobile?: boolean;
}

export default function MiniBasketPackagesList(
  props: MiniBasketPackagesListProps,
) {
  const { mobile } = props;

  const delegatePackagesMap = useRootSelector(
    selectSitePricingPackagesByDelegate,
  );
  const outdatedDelegatePackagesMap = useRootSelector(
    selectSitePricingOutdatedPackagesByDelegate,
  );

  return (
    <>
      <PackagesListByDelegate
        delegatePackagesMap={delegatePackagesMap}
        mobile={mobile}
      />
      <PackagesListByDelegate
        delegatePackagesMap={outdatedDelegatePackagesMap}
        outdated
        mobile={mobile}
      />
    </>
  );
}
