import { useRootSelector } from '../../../../../store/hooks';
import { selectSiteHeader } from '../../../../../store/features/siteHeader';
import { getFormattedDates } from '../../../helpers/dates';
import { selectSiteType } from '../../../../../store/features/siteType';
import { SiteTypes } from '../../../../../types';

export function usePackageDateAndLocation() {
  const { type: siteType } = useRootSelector(selectSiteType);
  const siteHeader = useRootSelector(selectSiteHeader);
  const {
    data: { startDate, endDate, venueCity, courseCode, courseOptions = [] },
  } = siteHeader;

  if (siteType === SiteTypes.COURSE) {
    const currentCourse = courseOptions.find(
      (option) => option.code === courseCode,
    );
    const {
      startDate: courseStartDate,
      endDate: courseEndDate,
      venue: { venueName = '', cityAsText = '' } = {},
    } = currentCourse || {};

    return {
      date: getFormattedDates({
        startDate: courseStartDate,
        endDate: courseEndDate,
      }),
      location: venueName || cityAsText,
    };
  }

  return {
    date: getFormattedDates({ startDate, endDate }),
    location: venueCity,
  };
}
