import { styled } from '@mui/material/styles';

const styledOptions = {
  shouldForwardProp: (prop: string) => !['bgImageUrl'].includes(prop),
};

interface ThumbnailImageProps {
  bgImageUrl?: string;
}

const OverlayStyledComponent = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
}));

export const StyledThumbnailWrapper = styled('div')(() => ({
  borderRadius: '8px',
  overflow: 'hidden',
  width: '100%',
  aspectRatio: 1,
  position: 'relative',
}));

export const StyledThumbnailBackground = styled(
  'div',
  styledOptions,
)<ThumbnailImageProps>(({ bgImageUrl }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  ...(bgImageUrl && {
        background: `url(${bgImageUrl}) center no-repeat`,
        backgroundSize: 'cover',
      }),
}));

export const StyledThumbnailForeground = styled(
  OverlayStyledComponent,
  styledOptions,
)<ThumbnailImageProps>(({ bgImageUrl }) => ({
  ...(bgImageUrl && {
    background: `url(${bgImageUrl}) center no-repeat`,
    backgroundSize: '90%',
  }),
}));

export const StyledThumbnailCover = styled(OverlayStyledComponent)(({ theme }) => ({
    ...{
      backgroundColor: theme.palette.brandSecondary.mainCustom
    }
  })
);

export const StyledThumbnailContent = styled(OverlayStyledComponent)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    color: theme.palette.neutral[0],
  }),
);
