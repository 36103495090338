import { ReactNode } from 'react';
import { PricingProduct } from '../../../store/features/products/types';
import { isPriceDiscount } from '../../../helpers/shared/isPriceDiscount';
import Price, { PriceProps } from '../Price';
import Tooltip from '../../library/Tooltip';
import { MuiIconButton } from '../../../../../modules/material';

import {
  StyledPriceInfoWrapper,
  StyledInfoIcon,
  StyledEndPriceWrapper,
} from './styles';
import { TypographyProps } from '../../library/Typography';

export interface PriceInfoProps extends PriceProps {
  price: PricingProduct['price'];
  startPrice?: PricingProduct['startPrice'];
  discountDetails?: ReactNode;
  isReverseOrderEnabled?: boolean;
  startPriceProps?: TypographyProps;
}

export default function PriceInfo(props: PriceInfoProps) {
  const {
    price,
    startPrice,
    discountDetails,
    isReverseOrderEnabled,
    startPriceProps,
    ...priceProps
  } = props;
  const showDiscountDetails = isPriceDiscount(price, startPrice);

  return (
    <StyledPriceInfoWrapper isReverseOrderEnabled={isReverseOrderEnabled}>
      <StyledEndPriceWrapper>
        <Price variant="h3" {...priceProps}>
          {price}
        </Price>

        {discountDetails && showDiscountDetails && (
          <Tooltip
            title={discountDetails}
            visualStyle="light"
            placement="bottom"
            data-testid="pricing-product-price-tooltip"
          >
            <MuiIconButton sx={{ p: 0 }}>
              <StyledInfoIcon fontSize="small" />
            </MuiIconButton>
          </Tooltip>
        )}
      </StyledEndPriceWrapper>

      {showDiscountDetails && (
        <Price
          variant="smallText"
          neutralColorIndex={700}
          opacity={0.6}
          lineThrough
          {...startPriceProps}
        >
          {startPrice}
        </Price>
      )}
    </StyledPriceInfoWrapper>
  );
}
