import {
  buttonClasses,
  fabClasses,
  alertClasses,
  badgeClasses,
  formLabelClasses,
  formControlLabelClasses,
  formHelperTextClasses,
  inputLabelClasses,
  selectClasses,
  outlinedInputClasses,
  menuClasses,
  menuItemClasses,
  listItemIconClasses,
  ThemeOptions,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

export const components: ThemeOptions['components'] = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h3',
        subtitle2: 'h3',
        caption1: 'span',
        caption2: 'span',
        superText: 'p',
        featuredText: 'p',
        body1: 'p',
        smallText: 'p',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecorationColor: 'inherit',
        color: 'inherit',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    // See more overrides: src/components/paymentJourneyRedesign/shared/library/Button/styles.ts
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.brandSecondary.darkCustom,
        boxShadow: 'none',
        border: 'none',
        '&:hover': {
          border: 'none',
          boxShadow: '2px 2px 12px 0px rgba(2, 16, 35, 0.10)',
        },
        '&:disabled': {
          border: 'none',
        },

        [`&.${buttonClasses.sizeMedium}`]: {
          ...theme.typography.button,
          padding: 'var(--payment-component-button-size-medium-padding)',
        },

        [`&.${buttonClasses.sizeSmall}`]: {
          ...theme.typography.buttonSmall,
          padding: 'var(--payment-component-button-size-small-padding)',
        },

        [`&.${buttonClasses.contained}`]: {
          '&:focus': {
            outline: `1px solid ${theme.palette.neutral[0]}`,
          },
          '&:disabled': {
            color: theme.palette.neutral[400],
            backgroundColor: theme.palette.neutral[100],
          },
        },

        [`&.${buttonClasses.contained}.${buttonClasses.colorPrimary}`]: {
          backgroundColor: theme.palette.brandAccent.mainCustom,
          '&:hover': {
            backgroundColor: theme.palette.brandAccent.mainCustom,
          },
          '&:disabled': {
            color: theme.palette.neutral[600],
            backgroundColor: theme.palette.neutral[400],
          },
        },

        [`&.${buttonClasses.contained}.${buttonClasses.colorSecondary}`]: {
          backgroundColor: theme.palette.brandPrimary.lightCustom,
          '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightCustom,
          },
        },

        [`&.${buttonClasses.contained}.${buttonClasses.colorError}`]: {
          backgroundColor: theme.palette.customError.mainOnLight,
          '&:hover': {
            backgroundColor: theme.palette.customError.mainOnLight,
          },
        },

        [`&.${buttonClasses.contained}.${buttonClasses.colorWarning}`]: {
          backgroundColor: theme.palette.customWarning.mainCustom,
          '&:hover': {
            backgroundColor: theme.palette.customWarning.mainCustom,
          },
        },

        [`&.${buttonClasses.contained}.${buttonClasses.colorSuccess}`]: {
          backgroundColor: theme.palette.customSuccess.mainCustom,
          '&:hover': {
            backgroundColor: theme.palette.customSuccess.mainCustom,
          },
        },

        [`&.${buttonClasses.contained}.${buttonClasses.colorInfo}`]: {
          backgroundColor: theme.palette.customInfo.mainCustom,
          '&:hover': {
            backgroundColor: theme.palette.customInfo.mainCustom,
          },
        },

        [`&.${buttonClasses.outlined}`]: {
          backgroundColor: theme.palette.neutral[0],
          outline: `1px solid ${theme.palette.neutral[200]}`,
          '&:hover': {
            backgroundColor: theme.palette.neutral[0],
            outline: `1px solid ${theme.palette.brandSecondary.darkCustom}`,
          },
          '&:focus': {
            backgroundColor: theme.palette.neutral[50],
            outline: `1px solid ${theme.palette.brandSecondary.darkCustom}`,
          },
          '&:disabled': {
            color: theme.palette.neutral[400],
            backgroundColor: theme.palette.neutral[50],
          },
        },

        [`&.${buttonClasses.text}`]: {
          textTransform: 'none',
          '&:hover': {
            color: theme.palette.customInfo.mainCustom,
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            color: theme.palette.neutral[400],
          },
        },

        [`&.${buttonClasses.text}.${buttonClasses.sizeSmall}`]: {
          backgroundColor: theme.palette.customInfo.mainCustom,
          '&:hover': {
            backgroundColor: theme.palette.customInfo.mainCustom,
          },
        },

        [`&.${buttonClasses.text}.${buttonClasses.sizeMedium}`]: {
          ...theme.typography.subtitle2,
          textTransform: 'none',
          padding: 0,
        },

        [`&.${buttonClasses.text}.${buttonClasses.sizeLarge}`]: {
          ...theme.typography.subtitle1,
          padding: 0,
        },
      }),
    },
  },
  MuiIconButton: {
    // See more overrides: src/components/paymentJourneyRedesign/shared/library/IconButton/styles.ts
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiFab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.neutral[900],
        boxShadow: '0px 4px 32px 0px rgba(8, 33, 66, 0.05)',
        '&:hover': {
          boxShadow: '2px 2px 12px 0px rgba(2, 16, 35, 0.10)',
        },
        '&:focus': {
          boxShadow: '2px 2px 12px 0px rgba(2, 16, 35, 0.10)',
          outline: `1px solid ${theme.palette.neutral[0]}`,
        },
        '&:disabled': {
          color: theme.palette.neutral[600],
          backgroundColor: theme.palette.neutral[400],
        },

        [`&.${fabClasses.primary}`]: {
          backgroundColor: theme.palette.brandAccent.mainCustom,
          '&:hover': {
            backgroundColor: theme.palette.brandAccent.mainCustom,
          },
        },

        [`&.${fabClasses.secondary}`]: {
          backgroundColor: theme.palette.brandSecondary.mainCustom,
          '&:hover': {
            backgroundColor: theme.palette.brandSecondary.mainCustom,
          },
        },
      }),
    },
  },
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        success: <CheckCircleIcon />,
        info: <InfoIcon />,
        warning: <WarningIcon />,
        error: <ErrorIcon />,
      },
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 'var(--payment-component-alert-border-radius)',
        padding: '8px 16px',

        [`& .${alertClasses.message}`]: {
          ...theme.typography.smallText,
          padding: 0,
        },

        [`& .${alertClasses.icon}`]: {
          marginRight: '8px',
          padding: 0,
        },

        [`&.${alertClasses.standard}.${alertClasses.colorSuccess}`]: {
          color: theme.palette.customSuccess.mainCustom,
          backgroundColor: theme.palette.customSuccess.lightCustom,

          ...(ownerState.stroke === 'standard' && {
            border: `1px solid rgba(${theme.palette.customSuccess.mainRGB}, 0.15)`,
          }),

          [`& .${alertClasses.icon}`]: {
            color: theme.palette.customSuccess.mainCustom,
          },
        },

        [`&.${alertClasses.standard}.${alertClasses.colorInfo}`]: {
          color: theme.palette.customInfo.mainCustom,
          backgroundColor: theme.palette.customInfo.lightCustom,

          ...(ownerState.stroke === 'standard' && {
            border: `1px solid rgba(${theme.palette.customInfo.mainRGB}, 0.15)`,
          }),

          [`& .${alertClasses.icon}`]: {
            color: theme.palette.customInfo.mainCustom,
          },
        },

        [`&.${alertClasses.standard}.${alertClasses.colorWarning}`]: {
          color: theme.palette.customWarning.mainCustom,
          backgroundColor: theme.palette.customWarning.lightCustom,

          ...(ownerState.stroke === 'standard' && {
            border: `1px solid rgba(${theme.palette.customWarning.mainRGB}, 0.15)`,
          }),

          [`& .${alertClasses.icon}`]: {
            color: theme.palette.customWarning.mainCustom,
          },
        },

        [`&.${alertClasses.standard}.${alertClasses.colorError}`]: {
          color: theme.palette.customError.darkCustom,
          backgroundColor: theme.palette.customError.lightCustom,

          ...(ownerState.stroke === 'standard' && {
            border: `1px solid rgba(${theme.palette.customError.darkRGB}, 0.15)`,
          }),

          [`& .${alertClasses.icon}`]: {
            color: theme.palette.customError.darkCustom,
          },
        },

        [`&.${alertClasses.filled}.${alertClasses.colorSuccess}`]: {
          backgroundColor: theme.palette.customSuccess.mainCustom,

          ...(ownerState.stroke === 'standard' && {
            border: `1px solid rgba(${theme.palette.customSuccess.darkRGB}, 0.15)`,
          }),
        },

        [`&.${alertClasses.filled}.${alertClasses.colorInfo}`]: {
          backgroundColor: theme.palette.customInfo.mainCustom,

          ...(ownerState.stroke === 'standard' && {
            border: `1px solid rgba(${theme.palette.customInfo.darkRGB}, 0.15)`,
          }),
        },

        [`&.${alertClasses.filled}.${alertClasses.colorWarning}`]: {
          backgroundColor: theme.palette.customWarning.mainCustom,

          ...(ownerState.stroke === 'standard' && {
            border: `1px solid rgba(${theme.palette.customWarning.darkRGB}, 0.15)`,
          }),
        },

        [`&.${alertClasses.filled}.${alertClasses.colorError}`]: {
          backgroundColor: theme.palette.customError.darkCustom,

          ...(ownerState.stroke === 'standard' && {
            border: `1px solid rgba(${theme.palette.customError.mainOnDarkRGB}, 0.15)`,
          }),
        },

        [`&.${alertClasses.outlined}.${alertClasses.colorSuccess}`]: {
          color: theme.palette.customSuccess.mainCustom,
          borderColor: theme.palette.customSuccess.mainCustom,

          [`& .${alertClasses.icon}`]: {
            color: theme.palette.customSuccess.mainCustom,
          },
        },

        [`&.${alertClasses.outlined}.${alertClasses.colorInfo}`]: {
          color: theme.palette.customInfo.mainCustom,
          borderColor: theme.palette.customInfo.mainCustom,

          [`& .${alertClasses.icon}`]: {
            color: theme.palette.customInfo.mainCustom,
          },
        },

        [`&.${alertClasses.outlined}.${alertClasses.colorWarning}`]: {
          color: theme.palette.customWarning.mainCustom,
          borderColor: theme.palette.customWarning.mainCustom,

          [`& .${alertClasses.icon}`]: {
            color: theme.palette.customWarning.mainCustom,
          },
        },

        [`&.${alertClasses.outlined}.${alertClasses.colorError}`]: {
          color: theme.palette.customError.darkCustom,
          borderColor: theme.palette.customError.darkCustom,

          [`& .${alertClasses.icon}`]: {
            color: theme.palette.customError.darkCustom,
          },
        },
      }),
    },
  },
  MuiBadge: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`& .${badgeClasses.colorPrimary}`]: {
          backgroundColor: theme.palette.brandAccent.mainCustom,
        },

        [`& .${badgeClasses.colorSecondary}`]: {
          backgroundColor: theme.palette.brandSecondary.mainCustom,
        },

        [`& .${badgeClasses.colorSuccess}`]: {
          backgroundColor: theme.palette.customSuccess.mainCustom,
        },

        [`& .${badgeClasses.colorWarning}`]: {
          backgroundColor: theme.palette.customWarning.mainCustom,
        },

        [`& .${badgeClasses.colorError}`]: {
          backgroundColor: theme.palette.customError.mainCustom,
        },

        [`& .${badgeClasses.colorInfo}`]: {
          backgroundColor: theme.palette.customInfo.mainCustom,
        },
      }),
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        color: theme.palette.neutral[600],
        [`& label.${inputLabelClasses.focused}`]: {
          color: theme.palette.neutral[600],
        },
        [`& label.${inputLabelClasses.error}`]: {
          color: theme.palette.neutral[600],
        },
        '& input': {
          padding: ownerState.label
            ? '24px 12px 8px 16px'
            : '16px 12px 16px 16px',
        },
        '& textarea': {
          padding: ownerState.label ? '8px 0 0 2px' : '0',
        },
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.body1,
        color: theme.palette.neutral[700],

        [`&.${formLabelClasses.focused}`]: {
          color: theme.palette.neutral[700],
        },

        [`&.${formLabelClasses.error}`]: {
          color: theme.palette.customError.darkCustom,
        },

        [`& .${formLabelClasses.asterisk}`]: {
          color: theme.palette.customError.mainOnLight,
        },
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`& .${formControlLabelClasses.asterisk}`]: {
          color: theme.palette.customError.mainOnLight,
        },
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.caption2,
        color: theme.palette.neutral[600],
        marginLeft: 0,

        [`&.${formHelperTextClasses.error}`]: {
          color: theme.palette.customError.darkCustom,
        },
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        left: '2px',
        color: theme.palette.neutral[600],

        [`&.${inputLabelClasses.error}`]: {
          color: theme.palette.neutral[600],
        },

        [`&.${inputLabelClasses.focused}`]: {
          color: theme.palette.neutral[600],
        },

        [`& .${inputLabelClasses.asterisk}`]: {
          color: theme.palette.customError.mainOnLight,
        },
      }),
    },
  },
  // See more overrides: src/components/paymentJourneyRedesign/shared/formElements/Select/index.tsx
  MuiSelect: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.body1,
        color: theme.palette.neutral[900],
        [`&.${selectClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.customError.darkCustom,
        },
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...theme.typography.body1,
        borderRadius: 'var(--payment-component-outlined-input-border-radius)',
        borderColor: theme.palette.neutral[200],
        backgroundColor: theme.palette.neutral[0],
        [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
          {
            borderWidth: '1px',
            ...(!ownerState.error && {
              borderColor: theme.palette.brandPrimary.mainCustom,
            }),
          },

        [`&:hover:not(.${outlinedInputClasses.focused}, .${outlinedInputClasses.error}, .${outlinedInputClasses.disabled}) .${outlinedInputClasses.notchedOutline}`]:
          {
            borderColor: theme.palette.neutral[800],
          },

        [`&.${outlinedInputClasses.error}`]: {
          borderColor: theme.palette.customError.darkCustom,
        },

        [`&.${outlinedInputClasses.disabled}`]: {
          backgroundColor: theme.palette.neutral[50],
        },

        '& fieldset': {
          ...(ownerState.error && {
            borderColor: `${theme.palette.customError.darkCustom} !important`,
          }),
        },
      }),
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiRadio: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`& .${menuClasses.paper}`]: {
          boxShadow: '2px 2px 12px 0px rgba(2, 16, 35, 0.10)',
          border: `1px solid ${theme.palette.neutral[200]}`,
        },
        [`& .${menuClasses.list}`]: {
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 'var(--payment-component-select-menu-border-radius)',
        },
      }),
    },
  },
  // See more overrides: src/components/paymentJourneyRedesign/shared/formElements/MuiMenuItem/index.tsx
  MuiMenuItem: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.body1,
        color: theme.palette.neutral[900],
        padding: '10px 16px',
        justifyContent: 'space-between',
        '&:hover': {
          backgroundColor: theme.palette.neutral[50],
        },
        fieldset: {
          outline: 'none !important',
        },
        [`&.${menuItemClasses.focusVisible}`]: {
          backgroundColor: 'inherit',
        },
        [`&.${menuItemClasses.selected}`]: {
          backgroundColor: theme.palette.neutral[100],
          '&:hover': {
            backgroundColor: theme.palette.neutral[100],
          },
        },
        [`&.${menuItemClasses.disabled}`]: {
          backgroundColor: theme.palette.neutral[50],
        },
        [`& .${listItemIconClasses.root}`]: {
          color: theme.palette.neutral[600],
          minWidth: 'auto',
        },
      }),
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.neutral[200],
      }),
    },
  },
};
