import { ChangeEvent, useState } from 'react';
import { useCookies } from 'react-cookie';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import { MuiGrid } from '../../../../../../../modules/material';
import { updateSitePricingPackage } from '../../../../../store/features/packages';
import { useRootDispatch } from '../../../../../../../store/hooks';

import { StyledNumberInputField, StyledIconButton } from './styles';

export interface NumbersBarProps {
  quantity: number;
  packageId: number;
}

const maxPackagesLimit = 99;
const minPackagesLimit = 1;

export default function NumbersBar(props: NumbersBarProps) {
  const { quantity, packageId } = props;
  const [cookies] = useCookies(['basket_id']);
  const { basket_id } = cookies;
  const dispatch = useRootDispatch();

  // The packageNumber is using to show correct count when
  // user changed the count but the new value is still not saved on server
  const [packageNumber, setPackageNumber] = useState<number | null>(null);
  const [isRequestInProgress, setIsRequestInProgress] =
    useState<boolean>(false);

  const updatePackageNumber = (newValue: number) => {
    setIsRequestInProgress(true);

    dispatch(
      updateSitePricingPackage({
        packageId,
        basketId: basket_id,
        quantity: newValue,
      }),
    ).then(() => {
      setPackageNumber(null);
      setIsRequestInProgress(false);
    });
  };

  const handleNumberChange = (newValue: number) => {
    const currentNumber = packageNumber || quantity;
    return updatePackageNumber(currentNumber + newValue);
  };

  const onBlurHandler = () => {
    const isValidNumber =
      packageNumber &&
      !isNaN(packageNumber) &&
      packageNumber <= maxPackagesLimit &&
      packageNumber !== quantity;

    if (isValidNumber) {
      updatePackageNumber(packageNumber);
    }
  };

  const onChangeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(event.target.value);
    const isValidValue =
      !isNaN(newQuantity) &&
      newQuantity >= minPackagesLimit &&
      newQuantity <= maxPackagesLimit;

    if (isValidValue) {
      setPackageNumber(newQuantity);
    }
  };

  return (
    <MuiGrid
      spacing={0.5}
      container
      alignItems="start"
      justifyContent="start"
      sx={{ flexWrap: 'nowrap' }}
    >
      <MuiGrid item>
        <StyledIconButton
          size="small"
          disabled={isRequestInProgress}
          onClick={() => handleNumberChange(-1)}
        >
          {quantity > 1 ? (
            <RemoveIcon fontSize="small" />
          ) : (
            <DeleteIcon fontSize="small" />
          )}
        </StyledIconButton>
      </MuiGrid>
      <MuiGrid item>
        <StyledNumberInputField
          type="number"
          value={packageNumber || quantity}
          onChange={onChangeInputHandler}
          disabled={isRequestInProgress}
          fullWidth={false}
          sx={{ borderRadius: '2px' }}
          inputProps={{
            min: minPackagesLimit,
            max: maxPackagesLimit,
            onBlur: onBlurHandler,
          }}
        />
      </MuiGrid>
      <MuiGrid item>
        <StyledIconButton
          size="small"
          disabled={quantity >= maxPackagesLimit || isRequestInProgress}
          onClick={() => handleNumberChange(1)}
        >
          <AddIcon fontSize="small" />
        </StyledIconButton>
      </MuiGrid>
    </MuiGrid>
  );
}
