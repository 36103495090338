import { useEffect, useState } from 'react';
import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import { MuiClickAwayListener } from '../../../../../modules/material';
import { useDeviceDetect } from '../../../../../hooks';
import { StyledTooltip } from './styles';

export enum TooltipVisualStyleTypes {
  default = 'default',
  light = 'light',
}

export interface TooltipProps extends MuiTooltipProps {
  enableClickMode?: boolean;
  visualStyle?: keyof typeof TooltipVisualStyleTypes;
}

// If you use Custom Child component be sure that this component forwards ref properly
export default function Tooltip(props: TooltipProps) {
  const {
    placement = 'top',
    arrow = true,
    visualStyle = TooltipVisualStyleTypes.default,
    children,
    enableClickMode,
    ...tooltipProps
  } = props;

  const [open, setOpen] = useState(false);
  const [isClickableMode, setIsClickableMode] = useState(false);

  const { isMobile, isTablet } = useDeviceDetect();

  useEffect(() => {
    setIsClickableMode(isTablet || isMobile || !!enableClickMode);
  }, [isMobile, isTablet, enableClickMode]);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (isClickableMode) {
    return (
      <MuiClickAwayListener onClickAway={handleTooltipClose}>
        <StyledTooltip
          open={open}
          placement={placement}
          arrow={arrow}
          visualStyle={visualStyle}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          {...tooltipProps}
        >
          <div onClick={handleTooltipOpen}>{children}</div>
        </StyledTooltip>
      </MuiClickAwayListener>
    );
  }

  return (
    <StyledTooltip
      placement={placement}
      arrow={arrow}
      visualStyle={visualStyle}
      {...tooltipProps}
    >
      {children}
    </StyledTooltip>
  );
}
