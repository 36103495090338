import { DrawerProps } from '@mui/material/Drawer';
import { MuiStack } from '../../../../../../../../modules/material';
import Title from '../../components/Title';
import CloseButton from '../../components/CloseButton';
import Discount from '../../components/Discount';
import MiniBasketPackagesList from '../../components/MiniBasketPackagesList';
import BasketViewFullButton from '../../../BasketViewFullButton';
import BasketCheckoutButton from '../../../BasketCheckoutButton';

import { StyledDrawer, StyledContainer } from './styles';

export interface MobileLayoutProps extends DrawerProps {
  onClose: () => void;
}

export default function MobileLayout(props: MobileLayoutProps) {
  const { onClose, ...drawerProps } = props;

  return (
    <StyledDrawer anchor="bottom" onClose={onClose} {...drawerProps}>
      <StyledContainer spacing={2}>
        <MuiStack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Title />
          <CloseButton onClick={onClose} />
        </MuiStack>
        <MuiStack spacing={0.5}>
          <Discount />
          <MiniBasketPackagesList mobile />
        </MuiStack>
        <MuiStack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <BasketViewFullButton />
          <BasketCheckoutButton />
        </MuiStack>
      </StyledContainer>
    </StyledDrawer>
  );
}
