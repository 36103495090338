import { Trans } from 'react-i18next';
import PackagesByDelegate from '../PackagesByDelegate';
import { PricingPackage } from '../../../../../../store/features/packages/types';
import Typography from '../../../../../library/Typography';

export interface PackagesListByDelegateProps {
  delegatePackagesMap: Record<string, PricingPackage[]>;
  outdated?: boolean;
  mobile?: boolean;
}

export default function PackagesListByDelegate(
  props: PackagesListByDelegateProps,
) {
  const { delegatePackagesMap, outdated, mobile } = props;

  const delegateKeys = Object.keys(delegatePackagesMap);

  return (
    <>
      {delegateKeys.map((delegateType: string, delegateTypeIndex: number) => {
        const packageList = delegatePackagesMap[delegateType];

        return (
          <div key={delegateType + delegateTypeIndex}>
            {delegateKeys.length > 1 && (
              <Typography component="div" sx={{ mt: 1.5 }}>
                <Trans
                  i18nKey="site.pricing.basket.package-list.delegate-type.title"
                  values={{ 0: delegateType }}
                  components={{ bold: <strong /> }}
                />
              </Typography>
            )}
            <PackagesByDelegate
              packageList={packageList}
              outdated={outdated}
              mobile={mobile}
            />
          </div>
        );
      })}
    </>
  );
}
