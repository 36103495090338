import Typography, { TypographyProps } from '../../../../library/Typography';
import { PricingPackageSelection } from '../../../../../store/features/packages/types';
import { StyledPackageDetailsWrapper, StyledRestProduct } from './styles';
import { MuiBox } from '../../../../../../../modules/material';

export interface PackageDetailsProps {
  packageSelection: PricingPackageSelection[];
  primaryProductProps?: TypographyProps;
  restProductsProps?: TypographyProps;
}

export default function PackageDetails(props: PackageDetailsProps) {
  const { packageSelection, primaryProductProps, restProductsProps } = props;
  const [primaryPackageSelection, ...restPackageSelections] = packageSelection;

  return (
    <StyledPackageDetailsWrapper>
      <Typography component="div" {...primaryProductProps}>
        {primaryPackageSelection.productName}
      </Typography>
      {restPackageSelections.length > 0 && (
        <MuiBox sx={{ mt: 0.5 }}>
          {restPackageSelections.map((addonItem: PricingPackageSelection) => (
            <StyledRestProduct
              key={addonItem.productId}
              neutralColorIndex={700}
              {...restProductsProps}
            >
              + {addonItem.productName}
            </StyledRestProduct>
          ))}
        </MuiBox>
      )}
    </StyledPackageDetailsWrapper>
  );
}
