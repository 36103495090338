import { useCallback, useEffect, useRef, useState } from 'react';
import { usePaymentContext } from '../../../PaymentProvider';
import { BadgeProps } from '../../../library/Badge';
import Button, { ButtonProps } from '../../../library/Button';
import MiniBasket from '../MiniBasket';

import { StyledBadge, StyledShoppingCartIcon } from './styles';

export interface MiniBasketButtonProps extends ButtonProps {
  ticketsCount?: BadgeProps['badgeContent'];
}

export default function MiniBasketButton(props: MiniBasketButtonProps) {
  const { ticketsCount, ...buttonProps } = props;

  const { setIsBasketFirstTimeAdded, isBasketFirstTimeAdded } =
    usePaymentContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setIsBasketFirstTimeAdded(false);
  }, [setOpen, setIsBasketFirstTimeAdded]);

  useEffect(() => {
    if (isBasketFirstTimeAdded) {
      handleOpen();
    }
  }, [isBasketFirstTimeAdded, handleOpen]);

  return (
    <>
      <Button
        variant="text"
        onClick={handleOpen}
        ref={buttonRef}
        {...buttonProps}
      >
        <StyledBadge badgeContent={ticketsCount}>
          <StyledShoppingCartIcon />
        </StyledBadge>
      </Button>
      <MiniBasket
        open={open}
        buttonAnchorEl={buttonRef.current}
        onClose={handleClose}
      />
    </>
  );
}
